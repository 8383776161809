<template>
  <div class="clients-contacts-container">
    <h1>Clients Contacts</h1>
    <alert class="my-4 light-shadow" v-if="isLoading" />
    <div>
      <alert class="my-4 light-shadow" v-if="!isLoading && !contacts.length"
        >No Contacts</alert
      >
    </div>
    <div
      class="card theme-card border-0 overflow-auto mobile-tablet-no-card"
      v-if="!isLoading && contacts.length"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div
          class="
            d-flex
            align-items-center
            mr-auto
            text-dark
            col-12 col-md-auto
            mt-0
            pl-1 pl-sm-3
            mb-3
            my-sm-4
          "
        >
          <span>
            <b>
              <i class="fas fa-spin fa-circle-notch" v-if="busy"></i>
              {{ busy ? "" : contacts.length }}/{{ total }}</b
            >
            Contact{{ total != 1 ? "s" : "" }}
          </span>
        </div>
        <JsonExcel
          :data="contacts"
          name="Clients-Contacts-Report"
          worksheet="Clients Contacts Report"
          :fields="excel_fields"
          :before-generate="loadRestOfData"
          default-value=""
          header="Clients Contacts Report"
        >
          <button class="btn btn-deep-success mr-3" :disabled="busy">
            <i class="far fa-file-excel fa-lg"></i> Save Excel
          </button>
        </JsonExcel>
      </div>
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Contact Name</th>
            <th>Relationship</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Address</th>
            <th>Medical Decisions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="contact in contacts" :key="contact.id">
            <td>
              <b class="mobile-tablet-only mr-2">Client Name:</b>
              <router-link :to="`/clients/${contact.patient_id}`">
                {{ contact.patient_name }}
              </router-link>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Contact Name:</b>
              <router-link :to="`/clients/${contact.patient_id}/contacts`">
                {{ contact.contact_name }}
              </router-link>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Relationship:</b
              >{{ contact.relationship }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Phone:</b>{{ contact.phone }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Email:</b
              ><a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Address:</b
              >{{ contact.address }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Medical Decisions:</b>
              {{ contact.medical_decisions == "1" ? "Yes" : "No" }}
            </td>
          </tr>
        </tbody>
      </table>
      <mugen-scroll
        :handler="loadMore"
        :should-handle="!busy"
        scroll-container="wrap"
      >
        <alert
          v-if="busy"
          class="
            text-center
            p-2
            mb-0
            mobile-tablet-loadmore mobile-tablet-shadow-card
          "
          ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more
          contacts...</alert
        >
      </mugen-scroll>
    </div>
    <loader v-if="processing"
      ><b class="mt-4 text-center" v-html="processingText"></b>
    </loader>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MugenScroll from "vue-mugen-scroll";
import JsonExcel from "vue-json-excel";

export default {
  name: "ClientsContacts",
  components: { MugenScroll, JsonExcel },
  data() {
    return {
      busy: false,
      limit: 100,
      percent: 0,
      processingText: "",
      processing: false,
      printExcelData: [],
      excel_fields: {
        "Client ID": {
          field: "patient_id",
        },
        "Client Name": {
          field: "patient_name",
        },
        "Contact Name": {
          field: "contact_name",
        },
        Relationship: {
          field: "relationship",
        },
        Phone: {
          field: "phone",
          callback: (value) => {
            return `${(value.toString().match(/.{1,28}/g) || []).join(
              ""
            )}&nbsp;`;
          },
        },
        Email: {
          field: "email",
        },
        Address: {
          field: "address",
        },
        "Medical Decisions": {
          field: "medical_decisions",
          callback: (value) => {
            return value && value == "1" ? "Yes" : "No";
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      contacts: (state) => state.patients.contacts.allContacts.data || [],
      current: (state) => state.patients.contacts.allContacts.current,
      total: (state) => state.patients.contacts.allContacts.total,
      isLoading: (state) => state.patients.contacts.isLoading,
    }),
  },
  mounted() {
    this.getFilteredContacts();
  },
  methods: {
    ...mapActions({
      getAllContacts: "patients/contacts/getAll",
    }),
    getFilteredContacts(page) {
      const payload = {
        limit: this.limit,
      };
      if (page) {
        payload.page = page;
      }
      return this.getAllContacts(payload).then(() => {
        this.busy = false;
        return Promise.resolve();
      });
    },
    loadMore() {
      if (this.contacts.length < this.total) {
        this.busy = true;
        this.getFilteredContacts(this.current + 1);
      }
    },
    async loadRestOfData() {
      const leftCount = this.total - this.contacts.length;
      if (leftCount == 0) {
        return Promise.resolve();
      } else {
        let numberOfRequests = parseInt(leftCount / this.limit);
        if (leftCount % this.limit > 0) {
          numberOfRequests++;
        }
        this.busy = true;
        this.processing = true;
        this.setProgressMessage(
          "Getting all data...<br>This may take few moments. Please don't refresh the page."
        );
        this.percent = 0;
        const requestEachTime = 3;
        let numberOfRequestGroup = parseInt(numberOfRequests / requestEachTime);
        numberOfRequestGroup += numberOfRequests % requestEachTime ? 1 : 0;
        let currentPage = this.current + 1;
        for (let i = 1; i <= numberOfRequestGroup; i++) {
          if (this._isDestroyed) {
            break;
          }
          await new Promise((resolve) => {
            const contactsPromisees = [];
            for (
              let j = 0;
              j < requestEachTime &&
              j + (i - 1) * requestEachTime < numberOfRequests;
              j++
            ) {
              contactsPromisees.push(this.getFilteredContacts(currentPage + j));
            }
            currentPage += requestEachTime;
            Promise.all(contactsPromisees).then(() => {
              this.percent = +parseFloat(
                ((i * requestEachTime * this.limit) / this.total) * 100
              ).toFixed(2);
              this.setProgressMessage(
                "Getting all data...<br>This may take few moments. Please don't refresh the page."
              );
              return resolve();
            });
          });
        }
        if (this.contacts.length == this.total) {
          this.percent = 0;
          this.busy = false;
          this.processing = false;
          return Promise.resolve();
        }
      }
    },
    setProgressMessage: function (message) {
      const percentMessage = `<h1 style="color: mediumspringgreen">${this.percent} %</h1>`;
      this.processingText = `${
        this.percent > -1 ? percentMessage : ""
      }${message}`;
    },
  },
};
</script>

<style scoped lang="scss">
.clients-contacts-container {
  .loader {
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.6);
    color: whitesmoke;
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    .responsive-mobile-tablet-table {
      padding: 2px;
    }
  }
}
</style>
